
const Footer = ()=>{
    return(
        <div className="bg-mainColor  p-5 rounded-t-[45px]">
            <p className="m-5 text-center text-2xl font-bold tracking-[2px]">CHERGUELAINE Oussama</p>
            <p className="m-5 text-center tracking-[1px]">© 2024  Tous droits réservés</p>
        </div>
    );
}

export default Footer; 